<template>
  <b-overlay
    :show="loading"
    rounded="sm">
    <div
      class="row mb-0">
      <div class="col-xs-7 col-sm-8 col-md-8 col-lg-9">
        <h5 class="page-title mb-0">
          <feather-icon
            :icon="formIcon"
            size="17"/> {{ formSubtitle }} <small class="text-primary">{{ title }}</small>
        </h5>
      </div>
      <div class="col-xs-5 col-sm-4 col-md-4 col-lg-3"/>
    </div>

    <hr
      class="mt-1 mb-1">
    <DxTreeList
      :data-source="formData.menus"
      :show-borders="true"
      :show-row-lines="true"
      :show-column-lines="true"
      :allow-column-reordering="true"
      :column-auto-width="true"
      :auto-expand-all="true"
      key-expr="id"
      parent-id-expr="parent_id"
    >
      <DxColumn
        caption="Name"
        data-field="name"
        width="100%"
      />
      <DxRowDragging
        :on-drag-change="onDragChange"
        :on-reorder="onReorder"
        :allow-drop-inside-item="false"
        :allow-reordering="true"
      />
    </DxTreeList>
    <hr class="mt-1 mb-2">
    <div>
      <button
        :disabled="loading"
        class="btn btn-primary mr-1 text-uppercase"
        title=""
        @click.prevent="submitForm">
        Save
      </button>
      <button
        :disabled="loading"
        class="btn btn-warning mr-1 text-uppercase"
        title=""
        @click.prevent="resetForm">
        Cancel
      </button>
    </div>
  </b-overlay>
</template>
<script>
import { DefaultFormData } from '@/modules/mixins/data'
import {
  DxTreeList,
  DxColumn,
  DxRowDragging
} from 'devextreme-vue/ui/tree-list'
import axiosIns from '../../libs/axios'

export default {
  name: 'CoachReorder',
  components: {
    DxTreeList,
    DxColumn,
    DxRowDragging
  },
  props: {
    formName: {
      type: String,
      default: 'CoachForm'
    },
    title: {
      type: String,
      required: true
    },
    formDataId: {
      type: Number,
      default: null
    },
    formHideEventName: {
      type: String,
      default: 'CoachFormHideEvent'
    },
    onFormClose: {
      type: String,
      default: null,
      required: true
    }
  },
  data() {
    const data = { ...DefaultFormData }
    this.$stateMerge(data, {
      formData: {
        menus: null
      }
    })
    return data
  },
  computed: {
    formSubtitle() {
      return 'Reorder'
    },
    formMethod() {
      return 'POST'
    },
    formAction() {
      return 'coachReorder'
    },
    formIcon() {
      return 'ListIcon'
    }
  },
  mounted() {
    this.loading = true
    axiosIns.post('/getCoachTreeViewReorder')
      .then(resp => {
        this.formData.menus = resp.data.data
        this.loading = false
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    onDragChange(e) {
      const visibleRows = e.component.getVisibleRows()
      const sourceNode = e.component.getNodeByKey(e.itemData.id)
      let targetNode = visibleRows[e.toIndex].node

      while (targetNode && targetNode.data !== undefined) {
        if (targetNode.data.id === sourceNode.data.id) {
          e.cancel = true
          break
        }
        targetNode = targetNode.parent
      }
    },
    onReorder(e) {
      const visibleRows = e.component.getVisibleRows()
      const sourceData = e.itemData
      const targetData = visibleRows[e.toIndex].data

      if (e.dropInsideItem) {
        if (targetData.has_children) {
          e.itemData.parent_id = targetData.id
          e.component.refresh()
        }
      } else {
        const sourceIndex = this.formData.menus.indexOf(sourceData)
        let targetIndex = this.formData.menus.indexOf(targetData)

        if (sourceData.parent_id !== targetData.parent_id) {
          sourceData.parent_id = targetData.parent_id
          if (e.toIndex > e.fromIndex) {
            // eslint-disable-next-line no-plusplus
            targetIndex++
          }
        }
        this.formData.menus.splice(sourceIndex, 1)
        this.formData.menus.splice(targetIndex, 0, sourceData)
        this.formData.menus = this.formData.menus.slice()
      }
    },
    submitForm() {
      console.log('this.formData.menus', this.formData.menus)
      this.loading = true

      this.$swal({
        // title: '',
        text: 'Do you really want to submit the form?',
        icon: 'question',
        reverseButtons: false,
        focusCancel: false,
        showCancelButton: true,
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-outline-success rounded-pill text-success',
          cancelButton: 'btn btn-outline-warning rounded-pill text-warning ml-1'
        },
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel'
      }).then(result => {
        const opt = {
          method: this.formMethod,
          url: this.formAction,
          data: this.formData
        }

        if (result.isConfirmed) {
          return axiosIns(opt).then(() => {
            this.msgShow('Data has been saved successfully')
            this.$events.fire(this.onFormClose, true)
          })
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }

}
</script>

<style scoped>

</style>
